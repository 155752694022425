import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import useSWR from "swr";
import { isArray } from "lodash";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useTranslation } from "react-i18next";
import { useFormik, FormikProvider } from "formik";
// import Tooltip from "../Components/Tooltip.js";
import ContantContainer from "../Components/ContactContainer.js";

import "../i18n.js";
import UserContext from "../UserContext.js";
import LayoutPrijemceMojeUdaje from "../Layouts/LayoutPrijemceMojeUdaje.js";
import LoadingIcon from "../Components/LoadingIcon.js";
import { Button } from "../Components/LinkButton";
import Styles from "./PrijemceMojeUdaje.module.scss";
import { HttpGet, HttpPost } from "../HTTP.js";
import FormInput, { FormInputGroup } from "../Components/FormInput.js";
import {
  validationSchema,
  validationSchemaInCaseOfNeed,
  validationSchemaIsMainPerson,
} from "./PrijemceMojeUdajeKontakty.formValidation.js";
import ConfirmWindow from "../Components/ConfirmWindow.js";
import useAccountLock from "../hooks/useAccountLock.js";
import RenderMD from "../Components/RenderMd.js";

const allFields = [
  "Gender.Id",
  "PersonRelation.Id",
  "Year",
  "Month",
  "Note",
  "FirstName",
  "LastName",
  "Phone",
  "Mail",
  "Description",
  "Profession",
  "Hobby",
  "Child",
  "Active",
  "Primary",
];

const PrijemceMojeUdajeKontakty = ({ useLayout = true, value, onChange }) => {
  const { t } = useTranslation();
  const MySwal = withReactContent(Swal);
  const user = useContext(UserContext);
  const { isLocked } = useAccountLock();

  const [profileData, setProfileData] = useState({});

  const [isLoading, setLoading] = useState(true);
  const [hasErrorLoading, setErrorLoading] = useState(false);
  const [persons, setPersons] = useState([]);
  const [isAddingNewPerson, setIsAddingNewPerson] = useState(false);
  const [isConfirmWindowOpen, setIsConfirmWindowOpen] = useState(false);
  const [deletePersonDescriptionString, setDeletePersonDescriptionString] =
    useState("");
  const [deletePersonId, setDeletePersonId] = useState(null);
  const [housing, setHousing] = useState(null);
  console.log("housing", housing);
  const [unsavedPerson, setUnsavedPerson] = useState(null);
  const [expandedPerson, setExpandedPerson] = useState(0);
  const { data: enumRecipientPersonRelation } = useSWR(
    "/enums/recipientPersonRelation"
  );

  const { data: enumHousingType } = useSWR("/getHousingTypeList");
  const housingTypes =
    enumHousingType &&
    enumHousingType.map((type) => ({
      value: type.Key,
      label: t(`frm-housingtype-${type.Value.replace("/podnájem", "")}`),
    }));

  useEffect(() => {
    if (
      persons &&
      housingTypes &&
      housingTypes.length > 0 &&
      persons.length === 0 &&
      isLoading
    ) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [housingTypes]);

  // useEffect(() => {
  //   if (profileData && !housing && housingTypes) {
  //     if (profileData.HousingType && profileData.HousingType.Id) {
  //       const filters = housingTypes.find((el) => el.Id === parseInt(profileData.HousingType.Id));
  //       console.log("filters", filters);
  //     }
  //   }
  // }, [housingTypes, housing, profileData])

  const handleAddPerson = () => {
    if (isLocked || isAddingNewPerson) return;
    setPersons([{ Id: null }, ...persons]);
    setExpandedPerson(0);
    setIsAddingNewPerson(true);
  };

  const loadData = () => {
    return new Promise((resolve, reject) => {
      setLoading(true);

      HttpGet("recipientProfile", false).then((resp) => {
        const formData = resp && resp.response;
        const errorCode = resp && resp.error_code;

        if (errorCode !== 200) {
          setErrorLoading(true);
          setLoading(false);
          reject(errorCode);
          return;
        }
        if (formData.HousingType && housingTypes.length) {
          if (formData.HousingType.Value) {
            const housingtype = housingTypes.find(
              (el) => el.value === parseInt(formData.HousingType.Id)
            );
            if (housingtype) setHousing(housingtype.value);
            else setHousing(null);
          }
        }

        const notShowPersons = formData.Persons.filter(
          (person) => person.PersonRelation && person.PersonRelation.Id === 14
        );

        const sortedPersons = [...formData.Persons].sort((a, b) => {
          if (a.PersonRelation && a.PersonRelation.Id === 13) return 1;
          if (b.PersonRelation && b.PersonRelation.Id === 13) return -1;

          if (a.Primary && !b.Primary) return -1;
          if (!a.Primary && b.Primary) return 1;

          if (a.Primary === b.Primary) {
            if (a.Active && !b.Active) return -1;
            if (!a.Active && b.Active) return 1;
          }
          return 0;
        });

        setExpandedPerson(null);
        setProfileData(formData);
        setPersons(sortedPersons);
        setPersons(
          sortedPersons.filter((person) => !notShowPersons.includes(person))
        );
        setErrorLoading(false);
        setLoading(false);
        resolve();
      });
    });
  };

  useEffect(() => {
    if (isLocked) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLocked]);

  const saveData = (data) => {
    return new Promise((resolve, reject) => {
      HttpPost("recipientProfile", data).then((resp) => {
        const errorCode = resp && resp.error_code;

        if (errorCode !== 200) {
          setLoading(false);

          // TODO: Push error to bugsnag ?
          // const formData = resp && resp.response;
          // console.error({ errorCode, formData, resp });

          reject(errorCode ? errorCode : resp._error);
          return;
        }

        resolve();
      });
    });
  };

  const handleUnsaved = (id) => {
    if (id) {
      setUnsavedPerson(id);
    }
  };

  const handleSave = (formItem, data) => {
    if (isLocked) return;
    //console.log("submit", { formItem, data });
    if (hasErrorLoading) return false;

    const updatedPersons = persons.map((item) => {
      if (String(item.Id) === String(formItem.Id)) {
        let updatedData = { ...data, Id: item.Id };

        if (!updatedData.Gender.Id) {
          updatedData.Gender = {
            Id: 1,
          };
        }

        if (
          updatedData.PersonRelation &&
          updatedData.PersonRelation.Id !== ""
        ) {
          updatedData.PersonRelation = {
            ...updatedData.PersonRelation,
            Id: Number(updatedData.PersonRelation.Id),
          };
        } else if (
          updatedData.PersonRelation &&
          updatedData.PersonRelation.Id === ""
        ) {
          updatedData.PersonRelation.Id = 1;
        }
        if (updatedData.Month && updatedData.Month !== "") {
          updatedData.Month = Number(updatedData.Month);
        }

        return updatedData;
      }
      return item;
    });

    let payload = {
      PersonId: user.personId,
      Recipient: {
        ...profileData,
      },
    };
    payload["Recipient"].Persons = updatedPersons;

    setLoading(true);
    setExpandedPerson(null);
    setIsAddingNewPerson(false);

    saveData(payload)
      .then(() => {
        MySwal.fire({
          title: t("frm-notification-saved"),
          icon: "success",
          timer: 2000,
          toast: true,
          position: "center",
          timerProgressBar: true,
          showConfirmButton: false,
        });

        loadData();
        setUnsavedPerson(null);
      })
      .catch((errorCode) => {
        MySwal.fire({
          title: t("frm-notification-save-error"),
          text: t(`myProfile-save-error-${errorCode}`),
          icon: "error",
          timer: 30000,
          position: "center",
          timerProgressBar: true,
          showConfirmButton: true,
        });
      });
  };

  const handlePersonDelete = (e) => {
    if (isLocked) return;

    if (e.Id === null) {
      const updatedPersons = persons.filter((person) => person.Id !== null);
      setPersons(updatedPersons);
      setIsAddingNewPerson(false);
      setExpandedPerson(null);
      return;
    }
    setIsConfirmWindowOpen(true);
    setDeletePersonId(e.Id);
  };

  const confirmPersonDelete = () => {
    if (!deletePersonId || !enumRecipientPersonRelation) return;

    const personToUpdateIndex = persons.findIndex(
      (person) => person.Id === deletePersonId
    );
    if (personToUpdateIndex === -1) return;

    const updatedPersons = JSON.parse(JSON.stringify(persons));
    const personToUpdate = updatedPersons[personToUpdateIndex];

    let originalRelationValue = "neznámý vztah";

    if (
      personToUpdate.PersonRelation &&
      personToUpdate.PersonRelation.Id != null
    ) {
      const originalRelationEnum = enumRecipientPersonRelation.find(
        (enumItem) => enumItem.value === personToUpdate.PersonRelation.Id
      );

      if (originalRelationEnum) {
        originalRelationValue = originalRelationEnum.label;
      }
    }
    const updatedDescription = `Originální vztah k pacientovi: ${originalRelationValue}. Důvod pro smazání: ${deletePersonDescriptionString}.`;

    personToUpdate.PersonRelation = { Id: 14, Value: "nezobrazovat" };
    personToUpdate.Description = updatedDescription;

    const payload = {
      PersonId: user.personId,
      Recipient: {
        ...profileData,
        Persons: updatedPersons,
      },
    };

    saveData(payload)
      .then(() => {
        MySwal.fire({
          title: t("frm-notification-person-deleted"),
          icon: "success",
          timer: 4000,
          toast: true,
          position: "center",
          timerProgressBar: true,
          showConfirmButton: false,
        });

        loadData();
        setUnsavedPerson(null);
      })
      .catch((errorCode) => {
        MySwal.fire({
          title: t("frm-notification-save-error"),
          text: t(`myProfile-save-error-${errorCode}`),
          icon: "error",
          timer: 30000,
          position: "center",
          timerProgressBar: true,
          showConfirmButton: true,
        });
      });
    setIsConfirmWindowOpen(false);
    setDeletePersonId(null);
  };
  const handleHousingTypeChange = (e) => {
    setLoading(true);
    const housingType = housingTypes.find(
      (el) => el.value === parseInt(e.target.value)
    );
    setProfileData({ ...profileData, HousingType: housingType });
    const allData = {
      ...profileData,
      HousingType: {
        Id: housingType.value,
        Value: housingType.label,
      },
    };
    HttpPost("recipientProfile", {
      PersonId: user.personId,
      Recipient: allData,
    }).then((resp) => {
      const errorCode = resp && resp.error_code;
      if (errorCode !== 200) {
        setErrorLoading(true);
        MySwal.fire({
          title: t("frm-notification-save-error"),
          text: t(`myProfile-save-error-${errorCode}`),
          icon: "error",
          timer: 30000,
          position: "center",
          timerProgressBar: true,
          showConfirmButton: true,
        });
        return;
      }
      MySwal.fire({
        title: t("frm-notification-saved"),
        icon: "success",
        timer: 2000,
        toast: true,
        position: "center",
        timerProgressBar: true,
        showConfirmButton: false,
      });
      setLoading(false);
    });
    setHousing(e.target.value);
  };

  const personToDelete = persons.find((item) => item.Id === deletePersonId);

  const renderPage = (children) => {
    if (useLayout) {
      return (
        <LayoutPrijemceMojeUdaje pageTitle={t("prijemce-kontaktniUdaje-title")}>
          {children}
        </LayoutPrijemceMojeUdaje>
      );
    } else return children;
  };

  return renderPage(
    <>
      <ConfirmWindow
        open={isConfirmWindowOpen}
        onClose={() => setIsConfirmWindowOpen(false)}
        title={t("frm-person-delete-title")}
        description={t("frm-person-delete-description").replace(
          "{x}",
          personToDelete
            ? `${personToDelete.FirstName ?? ""} ${
                personToDelete.LastName ?? ""
              }`
            : "Osoba nenalezena"
        )}
        value={deletePersonDescriptionString}
        setValue={(e) => setDeletePersonDescriptionString(e)}
        onConfirm={() => confirmPersonDelete()}
        placeholder={t("frm-person-delete-reason-placeholder")}
      />
      <section className={Styles.wrap}>
        {isLoading && (
          <div className={Styles.loading}>
            <LoadingIcon size={48} />
          </div>
        )}

        {hasErrorLoading && (
          <div>
            <div className="message is-danger">
              <div className="message-header">
                <p>{t("myProfile-error-title")}</p>
              </div>
              <div className="message-body">
                <p>{t("myProfile-error-body")}</p>
                <p>&nbsp;</p>
                <button
                  onClick={loadData}
                  className="button is-fullwidth is-primary is-outlined"
                >
                  {t("button-reload")}
                </button>
              </div>
            </div>
          </div>
        )}

        <h1 className={Styles.contactHeading}>
          {t("prijemce-kontaktniUdaje-title")}
        </h1>

        {!useLayout ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              itemsAlign: "center",
              marginBottom: 20,
              gap: 20,
            }}
          >
            <div>
              <h1 style={{ fontWeight: "bold", marginRight: 15 }}>
                {t("frm-housing-type")}:
              </h1>
              <FormInput
                name="housingType"
                type="select"
                options={housingTypes}
                value={housing}
                onChange={handleHousingTypeChange}
                style={{ height: 300 }}
                disabled={isLocked}
              />
            </div>
            <div>
              <h1 style={{ fontWeight: "bold", marginRight: 15 }}>
                {t("frm-persons-count")}:
              </h1>
              <FormInput
                name="personsCount"
                type="number"
                value={value}
                onChange={onChange}
                min={0}
                required
              />
            </div>
          </div>
        ) : (
          <div
            className={Styles.housingTypeStyle}
            style={{
              display: "flex",
              justifyContent: "space-between",
              itemsAlign: "center",
            }}
          >
            <div>
              <h1 style={{ fontWeight: "bold", marginRight: 15 }}>
                {t("frm-housing-type")}:
              </h1>
              <FormInput
                name="housingType"
                type="select"
                options={housingTypes}
                value={housing}
                onChange={handleHousingTypeChange}
                style={{ height: 300 }}
                disabled={isLocked}
              />
            </div>
          </div>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: 30,
            alignItems: "center",
            marginBottom: 30,
          }}
        >
          <div>
            <h1 className={Styles.smallTitle}>
              {t("prijemce-clenoveRodiny-title")}
            </h1>
            <p>
              <RenderMD>{t("prijemce-clenoveRodiny-description")}</RenderMD>
            </p>
          </div>
          <Button onClick={handleAddPerson} disabled={isLocked}>
            {t("frm-person-add-new")}
          </Button>
        </div>

        {persons &&
          isArray(persons) &&
          persons.map((item, idx) => (
            <section key={`${idx}`} className={Styles.person}>
              <PersonItem
                formData={item}
                isLoading={isLoading}
                canEdit={
                  (unsavedPerson === null && !isAddingNewPerson) ||
                  unsavedPerson === item.Id
                }
                hasErrorLoading={hasErrorLoading}
                handleSave={handleSave}
                onUnsaved={handleUnsaved}
                expanded={expandedPerson === idx}
                onDelete={() => handlePersonDelete(item)}
                isLocked={isLocked}
                onClick={() => setExpandedPerson(idx)}
              />
            </section>
          ))}
      </section>
    </>
  );
};

const PersonItem = ({
  formData,
  isLoading,
  canEdit,
  hasErrorLoading,
  handleSave,
  onUnsaved,
  expanded,
  onDelete,
  isLocked,
  onClick,
}) => {
  const { t } = useTranslation();
  const [didChange, setChange] = useState(false);
  const { data: enumRecipientPersonRelation } = useSWR(
    "/enums/recipientPersonRelation"
  );
  const recipientPersonRelation =
    enumRecipientPersonRelation &&
    enumRecipientPersonRelation
      .filter((type) => type.value !== 14)
      .map((type) => ({
        value: type.value,
        label:
          type.value === 13
            ? t("frm-personrelation-pripad-nouze")
            : t(
                `frm-personrelation-${type.label
                  .replace("/ka", "")
                  .replace(" ", "-")}`
              ),
      }));

  // eslint-disable-next-line no-unused-vars
  const [showUnsavedChanges, setShowUnsavedChanges] = useState(false);

  const { data: enumGender } = useSWR("/enums/gender");
  const enumGenders =
    enumGender &&
    enumGender.map((gender) => ({
      value: gender.value,
      label: gender.label === "?" ? "?" : t(`frm-gender-${gender.label}`),
    }));

  const enumMonths = Array.from({ length: 12 }, (_, i) => i + 1).map((item) => {
    return { value: item, label: t(`month_${item}`) };
  });

  enumMonths.unshift({ value: null, label: t("select_month"), disabled: true });

  const handleSubmit = (data) => {
    if (handleSave) {
      handleSave(formData, data);
      setChange(false);
    }
  };

  // const handleDeletePerson = () => {
  //   if (handleDelete) handleDelete(formData.Id);
  // };

  const setValues = () => {
    const fieldsToPopulate = allFields;
    fieldsToPopulate.forEach((field) => {
      const fieldP = field.includes(".") ? field.split(".") : field;
      let value = isArray(fieldP)
        ? formData[fieldP[0]]
          ? formData[fieldP[0]][fieldP[1]]
          : ""
        : formData[field];
      if (value === true) value = "true";
      if (value === false) value = "false";

      //console.log({ field, fieldP, value, fieldsToPopulate });
      formik.setFieldValue(field, value, false);
      formik.setFieldTouched(field, false, false);
    });
  };

  useEffect(() => {
    setValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
      setShowUnsavedChanges(true);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  useEffect(() => {
    if (didChange) {
      if (onUnsaved) onUnsaved(formData.Id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [didChange, onUnsaved]);

  const formik = useFormik({
    initialValues: {
      FirstName: "",
      LastName: "",
      Mail: "",
      Primary: false,
      PersonRelation: null,
      Phone: "",
      Month: null,
      Year: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const { errors, isValid } = formik;
  const handleChange = (e) => {
    formik.handleChange(e);
    setChange(true);
    setShowUnsavedChanges(false);
  };
  const selectedRelationLabel = recipientPersonRelation.find(
    (relation) => relation.value === formData.PersonRelation?.Id
  )?.label;

  if (!expanded)
    return (
      <>
        {didChange && (
          <div className={Styles.unsavedChangesContainer}>
            <i className={"fa-solid fa-pen"} />
            <p style={{ textAlign: "left" }}>{t("frm-person-is-not-saved")}</p>
          </div>
        )}
        {formik.values.PersonRelation &&
        parseInt(formik.values.PersonRelation.Id) === 13 ? (
          <ContantContainer
            nameHeader={
              `${formData.FirstName || ""} ${
                formData.LastName ?? ","
              }`.trim() || t("frm-person-name-not-filled")
            }
            phoneNumber={formData.Phone || ""}
            email={formData.Mail || ""}
            isRelation13={true}
            onClickDelete={() => onDelete()}
            onClickEdit={onClick}
            isPersonDisabled={canEdit !== true}
          />
        ) : (
          <ContantContainer
            nameHeader={
              `${formData.FirstName || ""} ${formData.LastName || ""}`.trim() ||
              t("frm-person-name-not-filled")
            }
            birthDate={
              formData.Month && formData.Year
                ? `${formData.Month}/${formData.Year}`
                : ""
            }
            isMain={formData.Primary || false}
            patientRelation={selectedRelationLabel || ""}
            phoneNumber={formData.Phone || ""}
            email={formData.Mail || ""}
            isRelation13={false}
            schoolOrWork={formData.Profession || ""}
            hobbies={formData.Hobby || ""}
            onClickDelete={() => onDelete()}
            onClickEdit={onClick}
            isPersonDisabled={canEdit !== true}
          />
        )}
      </>
    );

  return (
    <FormikProvider value={formik}>
      {canEdit !== true && (
        <div className={Styles.noteBlock}>
          {t("frm-person-another-person-is-not-saved")}
        </div>
      )}

      <form
        onSubmit={formik.handleSubmit}
        className={`${Styles.formStyle} ${Styles.formStyleExpanded} ${
          hasErrorLoading ? "hidden" : ""
        }`}
      >
        <div className={Styles.deleteButtonContainer}>
          <Button
            type="submit"
            disabled={!isValid || isLocked}
            onPress={handleSubmit}
            style={{ marginRight: 8, height: 35 }}
          >
            {t("frm-button-submit")}
          </Button>

          <Button
            style={{ height: 35, marginRight: 10 }}
            disabled={isLocked}
            onClick={() => onDelete()}
          >
            {/* <span className={`icon is-left text-white cursor-pointer flex-center`} onClick={(() => onDelete())} style={{ margin: 0 }}>
              <i className={`fa-regular fa-trash-alt`} />
            </span> */}
            {t("frm-person-delete")}
          </Button>
        </div>

        <FormInputGroup layout="1fr 1fr">
          <FormInput
            name="FirstName"
            label={t("frm-first-name")}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={errors}
            value={formik.values.FirstName}
            readOnly={canEdit !== true}
            disabled={isLocked}
          />

          <FormInput
            name="LastName"
            label={t("frm-last-name")}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={errors}
            value={formik.values.LastName}
            readOnly={canEdit !== true}
            disabled={isLocked}
          />
        </FormInputGroup>

        <FormInputGroup layout={"1fr 1fr"}>
          <div className={Styles.expandedPrimaryCheckbox}>
            {/* <Tooltip content={t("frm-person-primary-explanation")} tooltipStyle={{maxWidth:200}}> */}
            <FormInput
              type={"checkbox"}
              name="Primary"
              disabled={true}
              tooltip={t("frm-person-primary-explanation")}
              tooltipStyle={Styles.iconStyle}
              style={{ marginBottom: 15 }}
              label={t("frm-person-primary")}
              value={formData.Primary}
              onChange={handleChange}
            />
            {/* </Tooltip> */}
          </div>
        </FormInputGroup>

        <FormInputGroup layout="0.5fr 0.5fr 0.5fr 1fr">
          <FormInput
            name="Month"
            label={t("frm-birthdate-month")}
            type="select"
            options={enumMonths}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={errors}
            value={formik.values.Month || ""}
            readOnly={canEdit !== true}
            disabled={isLocked}
            hasSelectedValue={Boolean(formik.values.Month)}
          />

          <FormInput
            name="Year"
            label={t("frm-birthdate-year")}
            icon="calendar"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={errors}
            value={formik.values.Year}
            readOnly={canEdit !== true}
            disabled={isLocked}
          />
          <FormInput
            name="Gender.Id"
            label={t("frm-gender")}
            type="select"
            options={enumGenders ? enumGenders : []}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={errors}
            value={formik.values.Gender?.Id}
            readOnly={canEdit !== true}
            disabled={isLocked}
          />
          <FormInput
            name="PersonRelation.Id"
            label={t("frm-person-relation")}
            type="select"
            options={recipientPersonRelation ? recipientPersonRelation : []}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={errors}
            value={formik.values.PersonRelation?.Id}
            readOnly={canEdit !== true}
            disabled={isLocked}
          />
        </FormInputGroup>

        <FormInputGroup layout="1fr 1fr">
          <FormInput
            name="Phone"
            label={t("frm-phone")}
            //type="phone"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={errors}
            value={formik.values.Phone}
            readOnly={canEdit !== true}
            disabled={isLocked}
          />

          <FormInput
            name="Mail"
            label={t("frm-email")}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={errors}
            value={formik.values.Mail}
            readOnly={canEdit !== true}
            disabled={isLocked}
          />
        </FormInputGroup>
        {formik.values.PersonRelation?.Id + "" !== "13" && (
          <>
            <FormInputGroup layout="1fr">
              <FormInput
                name="Profession"
                label={t("frm-school-profession")}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                error={errors}
                value={formik.values.Profession}
                readOnly={canEdit !== true}
                disabled={isLocked}
              />
            </FormInputGroup>

            <FormInputGroup layout="1fr">
              <FormInput
                name="Hobby"
                label={t("frm-hobby")}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                error={errors}
                value={formik.values.Hobby}
                readOnly={canEdit !== true}
                disabled={isLocked}
              />
            </FormInputGroup>
          </>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "30%" }} />

          <div style={{ width: "30%", textAlign: "right" }}>
            {/*
            <Button onClick={handleDeletePerson} disabled={canEdit !== true}>
              {t("button-remove-person")}
            </Button>
            */}
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

PersonItem.propTypes = {
  formData: PropTypes.object,
  isLoading: PropTypes.bool,
  canEdit: PropTypes.bool,
  hasErrorLoading: PropTypes.bool,
  handleSave: PropTypes.func,
  onUnsaved: PropTypes.func,
  expanded: PropTypes.bool,
  onDelete: PropTypes.func,
  isLocked: PropTypes.bool,
  onClick: PropTypes.func,
};

PrijemceMojeUdajeKontakty.propTypes = {
  useLayout: PropTypes.bool,
  value: PropTypes.number,
  onChange: PropTypes.func,
};

PrijemceMojeUdajeKontakty.defaultProps = {
  useLayout: true,
  value: 0,
  onChange: () => {},
};

export default PrijemceMojeUdajeKontakty;
