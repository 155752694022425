/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from "react";
import LayoutBasic from "../Layouts/LayoutBasic.js";
import { useTranslation } from "react-i18next";
import { HttpGet, HttpPost } from "../HTTP";
import PropTypes from "prop-types";
import UserContext from "../UserContext.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Styles from "./PrijemceMojeFotky.module.scss";
import { Button } from "../Components/LinkButton.js";
import FormInput from "../Components/FormInput.js";
import Modal from "../Components/Modal.js";
import Loading from "../Components/Loading.js";
import { apiImageUrl } from "../appConfig.js";
import RenderMD from "../Components/RenderMd.js";
import ImageLightbox from "../Components/ImageLightbox.js";
import useAccountLock from "../hooks/useAccountLock.js";

const renderImageURL = (id) => `${apiImageUrl}${id}?isWatermark=false`;
const MAX_FILE_SIZE = 3;
const swalConf = {
  timer: 2000,
  toast: true,
  position: "center",
  timerProgressBar: true,
  showConfirmButton: false,
};

const ImageContainer = ({
  Id,
  Description,
  handleAddDescription,
  handleDelete,
  handleClick,
}) => {
  const { t } = useTranslation();
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      className={Styles.imageBlock}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {isHover && (
        <div className={Styles.imageControlsContainer}>
          {!Description && 1 === "a" && (
            <span
              className={Styles.imageControl}
              onClick={() => handleAddDescription(Id)}
            >
              <i className="fa-solid fa-comment" />
            </span>
          )}
          <span
            className={Styles.imageControl}
            onClick={() => handleDelete(Id)}
          >
            <i className="fa-solid fa-trash" />
          </span>
        </div>
      )}
      <img
        src={renderImageURL(Id)}
        className={Styles.image}
        alt={Id}
        onClick={() => handleClick()}
      />
      {Description ? (
        <p className={Styles.imageDescription}>{Description}</p>
      ) : (
        <p className={Styles.imageDescriptionMissing}>
          {t("photos-missing-description")}
        </p>
      )}
    </div>
  );
};

const ImageContainerAlt = ({
  Id,
  Description,
  handleAddDescription,
  handleDelete,
  handleClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className={Styles.imageContainerAltWrapper}>
      <div className={Styles.imageContainerAltImageContainer}>
        <div className={Styles.imageContainerAltImageWrapper}>
          <img
            src={renderImageURL(Id)}
            className={Styles.imageContainerAltImage}
            alt={Id}
            onClick={() => handleClick()}
          />
        </div>
      </div>
      <div className={Styles.imageContainerAltDescriptionWrapper}>
        {Description ? (
          <p className={Styles.imageContainerAltDescription}>{Description}</p>
        ) : (
          <p className={Styles.imageContainerAltDescriptionMissing}>
            {t("photos-missing-description")}
          </p>
        )}
      </div>
      <div className={Styles.imageContainerAltControlsWrapper}>
        {!Description && 1 === "a" && (
          <button
            className={`${Styles.imageContainerAltControl} ${Styles.imageContainerAltControlDescription}`}
            onClick={() => handleAddDescription(Id)}
          >
            <i className="fa-solid fa-comment" />
          </button>
        )}
        <button
          className={`${Styles.imageContainerAltControl} ${Styles.imageContainerAltControlDelete}`}
          onClick={() => handleDelete(Id)}
        >
          <i className="fa-solid fa-trash text-primary" />
        </button>
      </div>
    </div>
  );
};

const PrijemceMojeFotky = () => {
  const { t } = useTranslation();
  const uploadRef = useRef(null);
  // const { fullUser: user } = useContext(UserContext)
  const fullUser = useContext(UserContext);
  const user = fullUser.fullUser || {};
  const MySwal = withReactContent(Swal);
  const { renderAlert } = useAccountLock({ onlyBeforeWarning: true });

  const [eventType, setEventType] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [file, setFile] = useState(null);
  const [fullSizePhotoURL, setFullSizePhotoURL] = useState(null);
  const monthsCzech = [
    t("month_1"), // January
    t("month_2"), // February
    t("month_3"), // March
    t("month_4"), // April
    t("month_5"), // May
    t("month_6"), // June
    t("month_7"), // July
    t("month_8"), // August
    t("month_9"), // September
    t("month_10"), // October
    t("month_11"), // November
    t("month_12"), // December
  ];
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - 1990 + 1 },
    (_, i) => 1990 + i
  );
  const [month, setMonth] = useState("");
  const handleMonthChange = (e) => {
    setMonth(e.target.value);
  };
  const [year, setYear] = useState("");
  const handleYearChange = (e) => {
    setYear(e.target.value);
  };
  const [uploadedPhotoDescription, setUploadedPhotoDescription] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [lightboxIdx, setLightboxIdx] = useState(false);
  const [showDeletePhotoModal, setShowDeletePhotoModal] = useState(false);
  const handleUploadedPhotoDescriptionChange = (e) =>
    setUploadedPhotoDescription(e.target.value);

  useEffect(() => {
    HttpGet("recipientProfile", false, false).then((response) => {
      if (response.error_code !== 200) {
        if (user && user.Images) {
          setPhotos(user.Images);
        }
      } else {
        if (response.response.Images) {
          setPhotos(response.response.Images);
        }
      }
      setIsLoading(false);
    });
  }, [user]);

  const handleCloseLightbox = () => {
    setLightboxIdx(false);
  };

  const handleLightboxNext = () => {
    const currIdx = photos.findIndex((img) => img.Id === lightboxIdx);
    const nextIdx = currIdx + 1;
    if (nextIdx < photos.length) {
      setLightboxIdx(photos[nextIdx].Id);
    }
  };

  const handleLightboxPrev = () => {
    const currIdx = photos.findIndex((img) => img.Id === lightboxIdx);
    const prevIdx = currIdx - 1;
    if (prevIdx >= 0) {
      setLightboxIdx(photos[prevIdx].Id);
    }
  };

  const getHasNext = () => {
    return (
      photos.findIndex((img) => img.Id === lightboxIdx) + 1 < photos.length
    );
  };

  const getHasPrev = () => {
    return photos.findIndex((img) => img.Id === lightboxIdx) > 0;
  };

  const handleImageAddDescription = (e) => {
    setEventType("edit");
    setUploadedPhotoDescription(e.Description);
    setFullSizePhotoURL(renderImageURL(e.Id));
  };
  const handleImageDelete = (e) => {
    setEventType("delete");
    setFullSizePhotoURL(renderImageURL(e.Id));
  };
  const handleImageClick = (e) => {
    // setEventType("preview");
    // setFullSizePhotoURL(e.Id);
    setLightboxIdx(e.Id);
  };
  const handleEventTypeClose = () => {
    setEventType(null);
    setFullSizePhotoURL(null);
    setYear("");
    setMonth("");
    setUploadedPhotoDescription("");
    if (uploadRef.current) uploadRef.current.value = "";
  };
  const handleImageUploadClick = () => uploadRef.current.click();
  const handleUploadPhoto = (e) => {
    setYear("");
    setMonth("");
    setUploadedPhotoDescription("");
    const file = e.target.files[0];
    const acceptedTypes = ["image/png", "image/jpeg", "image/bmp", "image/jpg"];

    if (file?.size > MAX_FILE_SIZE * 1024 * 1024) {
      MySwal.fire({
        title: t("frm-photo-upload-size-exceeded"),
        icon: "error",
        timer: 2000,
        toast: true,
        position: "center",
        timerProgressBar: true,
        showConfirmButton: false,
        zIndex: 9999,
      });
      return;
    }

    if (!acceptedTypes.includes(file?.type)) {
      MySwal.fire({
        title: t("frm-photo-upload-type-error"),
        icon: "error",
        timer: 2000,
        toast: true,
        position: "center",
        timerProgressBar: true,
        showConfirmButton: false,
        zIndex: 9999,
      });
      return;
    }

    if (file) {
      setFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setEventType("upload");
        setFullSizePhotoURL(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const translateMonthToCzech = (month) => {
    const months = {
      january: "leden",
      february: "únor",
      march: "březen",
      april: "duben",
      may: "květen",
      june: "červen",
      july: "červenec",
      august: "srpen",
      september: "září",
      october: "říjen",
      november: "listopad",
      december: "prosinec",
    };

    return months[month.toLowerCase()] || month;
  };

  const onPhotoUpload = () => {
    if (fullSizePhotoURL && file) {
      const formData = new FormData();
      formData.append("Id", user.Id);
      formData.append("File", file);

      const formattedDescription = `${uploadedPhotoDescription}, ${translateMonthToCzech(
        month
      )} ${year}`;

      formData.append("Description", formattedDescription);
      HttpPost(
        "recipientUploadPhoto",
        formData,
        false,
        "multipart/form-data"
      ).then((response) => {
        if (response.error_code !== 200) {
          MySwal.fire({
            title: t("frm-photo-upload-failed"),
            icon: "error",
            ...swalConf,
          });
        } else {
          MySwal.fire({
            title: t("frm-photo-upload-success"),
            icon: "success",
            ...swalConf,
          });
        }
        setEventType(null);
        setFullSizePhotoURL(null);
        setYear("");
        setMonth("");
        setUploadedPhotoDescription("");
      });
    }
  };

  const onPhotoEdit = () => {
    if (fullSizePhotoURL) {
      HttpPost("recipient/edit-photo", {
        Id: user.Id,
        Image: fullSizePhotoURL,
        Description: uploadedPhotoDescription,
        Month: month,
        Year: year,
      }).then((response) => {
        if (response.error_code !== 200) {
          MySwal.fire({
            title: t("frm-photo-edit-failed"),
            icon: "error",
            ...swalConf,
          });
        } else {
          MySwal.fire({
            title: t("frm-photo-edit-success"),
            icon: "success",
            ...swalConf,
          });
        }
        setEventType(null);
        setFullSizePhotoURL(null);
        setYear("");
        setMonth("");
        setUploadedPhotoDescription("");
      });
    }
  };

  const onPhotoDelete = () => {
    const me =
      user.Persons.find((person) => person.Id === fullUser.personId) ?? "";

    if (fullSizePhotoURL) {
      HttpPost(`sendEmail`, {
        to: "web@dobryandel.cz",
        ...(me.Mail ? { replyTo: me.Mail } : {}),
        subject: `Žádost smazání fotografie`,
        fields: [
          {
            id: "personalNumber",
            title: "Číslo příjemce",
            value: user.PersonalNumber,
          },

          {
            id: "personEmail",
            title: "Email",
            value: me.Mail ?? "-",
          },

          {
            id: "image",
            title: "Adresa obrázku",
            value: fullSizePhotoURL,
          },

          {
            id: "description",
            title: "Odůvodnění",
            value: uploadedPhotoDescription,
          },
        ],
      }).then((resp) => {
        if (resp.error_code !== 200) {
          MySwal.fire({
            title: t("frm-photo-delete-failed"),
            icon: "error",
            ...swalConf,
          });

          return true;
        }

        // MySwal.fire({
        //   title: t("frm-photo-delete-success"),
        //   icon: "success",
        //   ...swalConf,
        // });

        setShowDeletePhotoModal(true);
        handleEventTypeClose();
      });
    }
  };

  const eventTypes = {
    preview: {
      title: t("frm-photo-preview-title"),
      button: t("frm-photo-preview"),
      children: (
        <div className={"flex-center"}>
          <img
            src={renderImageURL(fullSizePhotoURL)}
            className={Styles.fullScreenPhoto}
            alt={"fullscreen"}
          />
        </div>
      ),
    },
    uploadBefore: {
      title: t("frm-photo-upload-title"),
      button: t("frm-photo-upload"),
      children: (
        <>
          <div className={Styles.drawerContainer}>
            <div className={Styles.dndInput}>
              <input
                id="file"
                type="file"
                onChange={() => {}}
                accept=".jpg, .jpeg, .png, .bmp"
              />
              <div className={Styles.uploadButtons}>
                <i
                  className="fa-regular fa-upload"
                  onClick={() => document.getElementById("file").click()}
                  style={{ fontSize: 50 }}
                />
                <Button onClick={handleImageUploadClick}>
                  {t("photos-upload")}
                </Button>
              </div>
              <div className={Styles.allowedTypes}>
                <RenderMD>
                  {t("frm-photo-upload-before-description", {
                    maxFileSize: `${MAX_FILE_SIZE}MB`,
                  })}
                </RenderMD>
              </div>
            </div>
            <div>
              <div className={Styles.uploadTitle}>
                {t("frm-photo-upload-title")}
              </div>
            </div>
          </div>
        </>
      ),
    },
    upload: {
      title: t("frm-photo-upload-title"),
      button: t("frm-photo-upload"),
      children: (
        <>
          <div className={Styles.drawerContainer}>
            <div className={Styles.dndInput}>
              <img
                src={fullSizePhotoURL}
                className={Styles.fullScreenPhoto}
                alt={"fullscreen-upload"}
              />
            </div>
            <div>
              <div className={Styles.uploadTitle}>
                {t("frm-photo-upload-title")}
              </div>
              <div className={Styles.uploadDescription}>
                <RenderMD>{t("frm-photo-upload-description")}</RenderMD>
              </div>
              <div
                className={"flex-center"}
                style={{ flexDirection: "column" }}
              >
                {/* Month Select Input */}
                <div className={Styles.inputWrapper}>
                  <select
                    name="month"
                    onChange={handleMonthChange}
                    className={Styles.monthSelect}
                    defaultValue=""
                  >
                    <option value="" disabled>
                      {t("frm-photo-select-month")}
                    </option>
                    {monthsCzech.map((month) => (
                      <option value={month} key={month}>
                        {month}
                      </option>
                    ))}
                  </select>

                  {/* Year Select Input */}
                  <select
                    name="year"
                    onChange={handleYearChange}
                    className={Styles.yearSelect}
                    defaultValue=""
                  >
                    <option value="" disabled>
                      {t("frm-photo-select-year")}
                    </option>
                    {years.map((year) => (
                      <option value={year} key={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>

                <FormInput
                  type={"textarea"}
                  placeholder={t("frm-photo-description")}
                  value={uploadedPhotoDescription}
                  onChange={handleUploadedPhotoDescriptionChange}
                  name={"photo-description"}
                  style={{ width: "300px", minHeight: "70px", resize: "none" }}
                />
                <Button
                  disabled={!month || !year || !uploadedPhotoDescription}
                  onClick={() => onPhotoUpload()}
                >
                  {t("frm-photo-upload")}
                </Button>
              </div>
            </div>
          </div>
        </>
      ),
    },
    edit: {
      title: t("frm-photo-edit-title"),
      button: t("frm-photo-edit"),
      children: (
        <div className={"flex-center"} style={{ flexDirection: "column" }}>
          <img
            src={fullSizePhotoURL}
            className={Styles.fullScreenPhoto}
            alt={"fullscreen-upload"}
          />
          {/* Month Select Input */}
          <div className={Styles.inputWrapper}>
            <select
              name="month"
              onChange={handleMonthChange}
              className={Styles.monthSelect}
              defaultValue=""
            >
              <option value="" disabled>
                {t("frm-photo-select-month")}
              </option>
              {monthsCzech.map((month) => (
                <option value={month} key={month}>
                  {month}
                </option>
              ))}
            </select>

            {/* Year Select Input */}
            <select
              name="year"
              onChange={handleYearChange}
              className={Styles.yearSelect}
              defaultValue=""
            >
              <option value="" disabled>
                {t("frm-photo-select-year")}
              </option>
              {years.map((year) => (
                <option value={year} key={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
          <FormInput
            type={"textarea"}
            placeholder={t("frm-photo-description")}
            value={uploadedPhotoDescription}
            onChange={handleUploadedPhotoDescriptionChange}
            name={"photo-description"}
            style={{ width: "300px", minHeight: "70px", resize: "none" }}
          />
          <Button
            disabled={!month || !year || !uploadedPhotoDescription}
            onClick={() => onPhotoEdit()}
          >
            {t("frm-photo-edit")}
          </Button>
        </div>
      ),
    },
    delete: {
      title: t("frm-photo-delete-title"),
      button: t("frm-photo-delete"),
      children: (
        <div className={"flex-center"} style={{ flexDirection: "column" }}>
          <img
            src={fullSizePhotoURL}
            className={Styles.fullScreenPhoto}
            alt={"fullscreen-upload"}
          />
          <FormInput
            type={"textarea"}
            placeholder={t("frm-photo-delete-description")}
            value={uploadedPhotoDescription}
            onChange={handleUploadedPhotoDescriptionChange}
            name={"photo-description"}
            style={{ width: "300px", minHeight: "70px", resize: "none" }}
          />
          <Button
            onClick={() => onPhotoDelete()}
            disabled={
              uploadedPhotoDescription !== null &&
              uploadedPhotoDescription.length < 3
            }
          >
            {t("frm-photo-delete")}
          </Button>
        </div>
      ),
    },
  };

  return (
    <LayoutBasic pageTitle={t("prijemce-mojeFotky-title")}>
      {/* HIDDEN INPUT */}
      <input
        type={"file"}
        ref={uploadRef}
        onChange={handleUploadPhoto}
        style={{ display: "none" }}
        accept=".jpg, .jpeg, .png, .bmp"
      />

      <Modal open={eventType !== null} onClose={() => handleEventTypeClose()}>
        {eventTypes[eventType] !== undefined && eventTypes[eventType].children}
      </Modal>

      <Modal
        open={showDeletePhotoModal}
        onClose={() => setShowDeletePhotoModal(false)}
        title={t("prijemce-mojeFotky-photo-delete-title")}
      >
        <RenderMD>{t("prijemce-mojeFotky-photo-delete-description")}</RenderMD>
      </Modal>

      {lightboxIdx && (
        <ImageLightbox
          img={`${apiImageUrl}${lightboxIdx}?maxWidth=1600&isWatermark=false`}
          alt={photos.find((img) => img.Id === lightboxIdx)?.Description}
          title={photos.find((img) => img.Id === lightboxIdx)?.Description}
          onClose={handleCloseLightbox}
          onNext={handleLightboxNext}
          hasNext={getHasNext}
          onPrev={handleLightboxPrev}
          hasPrev={getHasPrev}
        />
      )}

      <div>
        {renderAlert()}
        <div className={Styles.header}>
          <h1 className={Styles.headerText}>Moje fotky</h1>
          <Button onClick={() => setEventType("uploadBefore")}>
            {t("photos-upload")}
          </Button>
        </div>
        <div className={Styles.description}>
          <RenderMD>{t("prijemce-mojeFotky-description")}</RenderMD>
        </div>

        {isLoading && <Loading />}

        {!isLoading && photos.length === 0 ? (
          <div className={Styles.errorContainer}>
            <p>{t("photos-none-uploaded")}</p>
          </div>
        ) : (
          <>
            {/* <div className={Styles.imageContainer}>
              {photos.map((photo) => (
                <ImageContainer
                  {...photo}
                  key={photo.Id}
                  handleAddDescription={() => handleImageAddDescription(photo)}
                  handleDelete={() => handleImageDelete(photo)}
                  handleClick={() => handleImageClick(photo)}
                />
              ))}
            </div> */}
            <div className={Styles.imageContainerAlt}>
              {photos.map((photo) => {
                return (
                  <ImageContainerAlt
                    {...photo}
                    key={photo.Id}
                    handleAddDescription={() =>
                      handleImageAddDescription(photo)
                    }
                    handleDelete={() => handleImageDelete(photo)}
                    handleClick={() => handleImageClick(photo)}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
    </LayoutBasic>
  );
};

ImageContainer.propTypes = {
  Id: PropTypes.string.isRequired,
  Description: PropTypes.string,
  handleAddDescription: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
};

ImageContainerAlt.propTypes = {
  Id: PropTypes.string.isRequired,
  Description: PropTypes.string,
  handleAddDescription: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default PrijemceMojeFotky;
